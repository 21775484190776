'use client';

import { Button } from '@/components/ui/button';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const { t } = useTranslation('common');
  const router = useRouter();

  return (
    <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
      <span className="bg-gradient-to-b from-foreground to-transparent bg-clip-text text-black text-[10rem] font-extrabold leading-none text-transparent">
        404
      </span>
      <h2 className="my-2 font-heading text-2xl font-bold">
        {t('notFound.title')}
      </h2>
      <p>{t('notFound.description')}</p>
      <div className="mt-8 flex justify-center gap-2">
        <Button onClick={() => router.back()} variant="default" size="lg">
          {t('goBack')}
        </Button>
        <Button
          onClick={() => router.push('/bookings')}
          variant="ghost"
          size="lg"
        >
          {t('goToHome')}
        </Button>
      </div>
    </div>
  );
}
